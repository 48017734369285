import type { CookieSerializeOptions } from 'cookie';
import * as cookie from 'cookie';

/**
 * A Storage implementation that uses cookies.
 */
export class CookieStorage implements Storage {
  options: CookieSerializeOptions;

  constructor(options: CookieSerializeOptions) {
    this.options = options;
  }
  [name: string]: any;

  get length(): number {
    return Object.keys(cookie.parse(document.cookie)).length;
  }

  clear(): void {
    Object.keys(cookie.parse(document.cookie)).forEach((key) => {
      document.cookie = cookie.serialize(key, '', {
        ...this.options,
        expires: new Date(),
        maxAge: -1,
      });
    });
  }

  getItem(key: string): string | null {
    try {
      const parsedCookie = cookie.parse(document.cookie);
      const item = parsedCookie[key];

      // Handle the case where the item exists but isn't JSON
      if (item) {
        try {
          // Try to parse as JSON to see if it's valid
          JSON.parse(item);
          return item;
        } catch (e) {
          // If not valid JSON, return it as a JSON string so it can be parsed
          return JSON.stringify(item);
        }
      }

      return item ?? null;
    } catch (e) {
      console.error('Error parsing cookie', e);
      return null;
    }
  }

  key(index: number): string | null {
    const key = Object.keys(cookie.parse(document.cookie))[index];
    return this.getItem(key);
  }

  removeItem(key: string): void {
    document.cookie = cookie.serialize(key, '', {
      ...this.options,
      expires: new Date(),
      maxAge: -1,
    });
  }

  setItem(key: string, value: string): void {
    document.cookie = cookie.serialize(key, value, this.options);
  }
}
