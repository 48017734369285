import { useEffect } from 'react';

import bg4 from '@/assets/images/bg-four.png';
import bg1 from '@/assets/images/bg-one.png';
import bg3 from '@/assets/images/bg-three.png';
import bg2 from '@/assets/images/bg-two.png';

const backgrounds = [bg1, bg2, bg3, bg4];

const randomBackground = backgrounds[Math.floor(Math.random() * backgrounds.length)];

export function RandomBackground({ children }: React.PropsWithChildren): React.ReactElement {
  useEffect(() => {
    const root = document.querySelector<HTMLBodyElement>('body');
    if (root) {
      root.style.background = `url(${randomBackground})`;
      root.style.backgroundSize = 'cover';
    }
  }, []);

  return <>{children}</>;
}
