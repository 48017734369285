import { useEffect } from 'react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

function TopLevelRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate({ to: '/session/new' });
  }, [navigate]);

  return null;
}
export const Route = createFileRoute('/')({
  component: TopLevelRedirect,
});
