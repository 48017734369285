import { useCallback } from 'react';

import { usePersistentState } from '@crossnokaye/ux-common';

import { CookieStorage } from '@repo/shared';

import { COOKIE_DOMAIN, StorageKey } from '@/constants';

import { EphemeralCookie, EphemeralCookieSchema, Flow, FlowSearchParams } from './models';

const cookieStorage = new CookieStorage({
  domain: COOKIE_DOMAIN,
  maxAge: 30,
  path: '/',
  sameSite: 'lax',
});

/**
 * Interact with the ephemeral cookie.
 */
export function useEphemeralCookie() {
  return usePersistentState<EphemeralCookie>(
    cookieStorage,
    StorageKey.ephemeralCookie,
    EphemeralCookieSchema
  );
}

/**
 * Interact with the flow state in session storage.
 */
export function useFlow() {
  return usePersistentState<Flow>(sessionStorage, StorageKey.flow);
}

/**
 * Return the code, state, etc from the URL (iff both are present).
 */
export function useFlowParams(): FlowSearchParams | undefined {
  const href = new URL(window.location.href);
  const code = href.searchParams.get('code');
  const state = href.searchParams.get('state');
  if (code && state) {
    return { code, state };
  }
}

/**
 * Navigate to the given URL (which is typically at another hostname)
 * without relying on router or single-page app navigation.
 */
export function useNavigateExternally() {
  return useCallback(({ to }: { to: string }) => {
    window.location.href = to;
  }, []);
}
