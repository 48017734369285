import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@crossnokaye/ux-common';

function toTitleCase(str: string) {
  return str.replace(/([A-Z])/g, ' $1').trim();
}

function toSentence(str: string) {
  str = str.trim();
  if (!/[^A-Za-z0-9]$/.test(str)) {
    str = str + '.';
  }
  if (/[A-Z]/.test(str)) {
    str = str[0].toUpperCase() + str.slice(1);
  }
  return str;
}

export function ErrorCard({ error }: { error: Error | Response }) {
  const message = error instanceof Error ? error.message : 'An HTTP request failed';
  const name = error instanceof Error ? error.name : error.statusText;

  return (
    <Card className={'w-1/2'}>
      <CardHeader>
        <CardTitle>{'Internal Error'}</CardTitle>
        {name === 'Error' ? null : <CardDescription>{toTitleCase(name)}</CardDescription>}
      </CardHeader>
      <CardContent>{toSentence(message)}</CardContent>
    </Card>
  );
}

export function SessionCard({ data, onLogout }: { data: { name: string }; onLogout: () => void }) {
  return (
    <Card className={'w-1/2'}>
      <CardHeader>
        <CardTitle>{"You're logged in!"}</CardTitle>
        <CardDescription>
          {'Hello, '}
          {data.name}
          {'.'}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Button
          type={'submit'}
          className={'uppercase flex justify-center items-center w-full font-bold'}
          onClick={onLogout}
        >
          {'Logout'}
        </Button>
      </CardContent>
    </Card>
  );
}
