import { Check } from 'lucide-react';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  LoadingButton,
} from '@crossnokaye/ux-common';

const discoverySchema = z.object({
  email: z.string().email({ message: 'Please enter a valid email' }),
  remember: z.boolean().default(false).optional(),
});

export interface DiscoveryFormProps {
  defaultEmail: string;
  onSubmit: (formValues: z.infer<typeof discoverySchema>) => Promise<void>;
}

export const DiscoveryForm = ({ defaultEmail, onSubmit }: DiscoveryFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<z.infer<typeof discoverySchema>>({
    resolver: zodResolver(discoverySchema),
    defaultValues: {
      email: defaultEmail,
      remember: !!defaultEmail,
    },
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(async (values) => {
          try {
            setIsLoading(true);
            await onSubmit(values);
          } finally {
            setIsLoading(false);
          }
        })}
        className={'flex flex-col items-center gap-y-6 min-w-80 md:min-w-96'}
        data-testid={'login-form'}
        style={{ zIndex: 1 }}
      >
        <FormField
          control={form.control}
          name={'email'}
          render={({ field }) => (
            <FormItem className={'w-full'}>
              <FormLabel htmlFor={'email'}>{'User Email'}</FormLabel>
              <FormControl>
                <Input id={'email'} autoComplete={'username'} disabled={isLoading} {...field} />
              </FormControl>
              <FormMessage>{form.formState.errors.email?.message}</FormMessage>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name={'remember'}
          render={({ field }) => (
            <FormItem className={'w-full'}>
              <FormControl>
                <Checkbox
                  id={'remember'}
                  checked={field.value}
                  onCheckedChange={(checked: boolean) => form.setValue('remember', checked)}
                  data-testid={'remember-me-checkbox'}
                />
              </FormControl>
              <FormLabel htmlFor={'remember'} className={'ml-2'}>
                {'Remember me next time I sign in'}
              </FormLabel>
            </FormItem>
          )}
        />
        <LoginButton isLoading={isLoading} />
      </form>
    </Form>
  );
};

const passwordSchema = z.object({
  password: z.string(),
});

export interface PasswordFormProps {
  email: string;
  onSubmit: (formValues: z.infer<typeof passwordSchema>) => Promise<void>;
}

export const PasswordForm = ({ email, onSubmit }: PasswordFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const form = useForm<z.infer<typeof passwordSchema>>({
    resolver: zodResolver(passwordSchema),
    defaultValues: {
      password: '',
    },
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(async (values) => {
          try {
            setIsLoading(true);
            await onSubmit(values);
          } finally {
            setIsLoading(false);
            setIsSuccess(true);
          }
        })}
        className={'flex flex-col items-center gap-y-6 min-w-80 md:min-w-96'}
        style={{ zIndex: 1 }}
      >
        <FormItem className={'w-full'}>
          <FormLabel>{'User Email'}</FormLabel>
          <FormControl>
            <Input disabled value={email} />
          </FormControl>
        </FormItem>
        <FormField
          control={form.control}
          name={'password'}
          render={({ field }) => (
            <FormItem className={'w-full'}>
              <FormLabel htmlFor={'password'}>{'Password'}</FormLabel>
              <FormControl>
                <Input
                  id={'password'}
                  autoComplete={'current-password'}
                  type={'password'}
                  {...field}
                />
              </FormControl>
              <FormMessage>{form.formState.errors.password?.message}</FormMessage>
            </FormItem>
          )}
        />
        <LoginButton isSuccess={isSuccess} isLoading={isLoading} />
      </form>
    </Form>
  );
};

export interface LoginButtonProps {
  isSuccess?: boolean;
  isLoading?: boolean;
}

export const LoginButton: FC<LoginButtonProps> = ({ isSuccess, isLoading }) => {
  return (
    <div className={'transition-all duration-300 w-full'}>
      <LoadingButton
        type={'submit'}
        className={'uppercase flex justify-center items-center w-full font-bold'}
        isLoading={isLoading}
      >
        {isSuccess ? <Check /> : 'sign in'}
      </LoadingButton>
    </div>
  );
};
